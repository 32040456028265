import { defineNuxtRouteMiddleware, clearError } from '#app'
import { useBrowser } from '#imports'

export default defineNuxtRouteMiddleware((to) => {
  if (!import.meta.client) return
  const browser = useBrowser()
  if (to.fullPath.includes('/advertise') && browser.value !== 'SHIFT') {
    return clearError({
      redirect: '/partnerships',
    })
  }
})
